<template>
    <div class="px-3 md:px-0 pt-4">
        
            <p class="text-xl leading-8 font-semibold tracking-tight text-gray-900 capitalize mb-6" v-if="$route.params.tipe_modul == 'event'">
                Bergabung ke Event
            </p>
            <p class="text-xl leading-8 font-semibold tracking-tight text-gray-900 capitalize mb-6" v-if="$route.params.tipe_modul == 'berbayar'">
                Beli Paket
            </p>

            <div v-if="modulList.length > 0">
                <router-link :to="{
                    name : 'detailModul',
                    params : {
                        'singkatan_kategori': data.kategori.singkatan.toLowerCase(),
                        'tipe_modul'        : $route.params.tipe_modul,
                        'id_paket'          : data.id_paket
                    }
                }" class="flex items-center bg-white py-2 px-3 shadow-md rounded-lg my-2 transition-colors hover:border-gray-300"
                v-for="(data, index) in modulList" :key="index">
                    <div class="w-16">
                        <div class="h-16 w-16 bg-yellow-100 rounded-full content-center flex items-center text-center">
                            <img src="../assets/images/package.svg" class="h-10 w-10 mx-auto"/>
                        </div>
                    </div>

                    <div class="flex-grow ml-3 pt-1 pb-1">
                        <h2 class="font-semibold tracking-tight text-gray-800 text-xl">{{ data.nama_paket }}</h2>
                        
                        <span class="mr-5 text-gray-700 text-based">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block -mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                            </svg>
                            <span class="hidden md:inline">{{ data.kategori.judul }}</span>
                            <span class="md:hidden">{{ data.kategori.singkatan }}</span>
                        </span>

                        <span class="mr-5 text-gray-700 text-based">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block -mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg>
                            <span v-if="data.harga_paket == null">
                                Gratis
                            </span>
                            <span v-else>
                                Rp. {{ data.harga_paket | moneyFormat }}
                            </span>
                        </span>
                        <span class="mr-2 text-gray-700 text-based">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block -mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                            </svg>
                            {{ data.kuis_count }} Kuis
                        </span>
                    </div>
                </router-link>
            </div>

            <div class="animate-pulse" v-if="isLoadingPaket">
                <div class="flex bg-white py-2 px-3 border rounded-md my-2 transition-colors" v-for="i in 3" :key="i">
                    <div class="h-16 w-16 bg-gray-100 rounded-lg content-center flex items-center text-center">
                    </div>

                    <div class="flex-grow ml-3 pt-1 pb-1">
                        <div class="h-6 bg-gray-100 rounded"></div>
                        <div class="h-5 w-32 mt-2 inline-block mr-2 bg-gray-100 rounded"></div>
                        <div class="h-5 w-40 mt-2 inline-block bg-gray-100 rounded"></div>
                    </div>
                </div>
            </div>
            
            <div v-if="!isLoadingPaket && modulList.length == 0" class="text-center w-full mt-6" >
                <div class="w-2/3 px-5 mx-auto">
                    <img src="@/assets/images/cloud-computing.svg" class="w-40 mx-auto" />
                    <p class="mt-1 text-gray-800 font-semibold">Maaf, data masih kosong</p>
                </div>
            </div>
    </div>
</template>
<script>
import { combineObject, errorHandler, showErrorNotif } from '../helpers/Tools';
export default {
    name : 'ExplorePaket',
    data(){
        return {
            isLoadingPaket: true,
            modulList     : [],
            page          : 1,
            isEmpty       : false
        }
    },
    mounted() {
        this.getModulList(this.page);
        this.scroll();
    },
    watch: {
        "$route.params.tipe_modul": function (id) {
            this.page = 1;
            this.getModulList();
            this.scroll();
        },
    },
    destroyed() {
        window.onscroll = () => {
        }
    },
    methods : {
        refresh(loaded) {
            this.page = 1;
            this.getModulList();
            loaded('done')
        },
        scroll() {
            window.onscroll = () => {
                const bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
                if (bottomOfWindow && !this.isEmpty) {
                    this.getModulList()
                }
            }
        },
        async getModulList() {
            this.isLoadingPaket = true;
            try {
                let res = await this.$store.dispatch("getModulByStatusBayar", {
                    status_akses: this.$route.params.tipe_modul,
                    page        : this.page
                });
                this.isLoadingPaket = false;
                let result = res.data;
                if(result.status == 'success') {
                    
                    if(result.data.data.length == 0) {
                        this.isEmpty = true;
                    }

                    if(this.page == 1){
                        this.modulList = result.data.data;
                    }else{
                        this.modulList = combineObject(this.modulList, result.data.data, 'id');
                    }
                    this.page++;

                } else {
                    this.isEmpty = true;
                    showErrorNotif(this, result.message);
                }
            } catch (error) {
                this.isLoadingPaket = false;
                errorHandler(this, error);
            }
        }
    }
}
</script>